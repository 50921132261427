// Vuetify
import '@mdi/font/css/materialdesignicons.css';
import "vuetify/styles";
import {createVuetify} from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import {aliases, mdi} from 'vuetify/iconsets/mdi';
import {de} from 'vuetify/locale';

const vuetify = createVuetify({
    components,
    directives,

    locale: {
        locale: 'de',
        fallback: 'en',
        messages: {de}
    },

    theme: {
        defaultTheme: 'joiny',
        themes: {
            joiny: {
                // colors: {
                //     'primary': '#0FE000'
                // }
            }
        }
    },

    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },

    defaults: {
        global: {
            ripple: false,
        },

        VBtn: {
            class: 'text-none',
            variant: 'flat',
            rounded: 'xl',
        },

        VCard: {
            rounded: 'xl',
            variant: 'flat',
            class: 'pa-2',
        },

        VDialog: {
            VCard: {
                VCardActions: {
                    VBtn: {
                        variant: 'flat'
                    }
                }
            }
        },

        VMenu: {
            VList: {
                elevation: 0,
                slim: true,
                class: 'pa-0 border mt-2'
            },
        }
    }
});

export default vuetify;
