<script setup>
import Default from "@/Layouts/Default.vue";
import DetailsWrapper from "@/Pages/Console/Projects/DetailsWrapper.vue";
import draggable from 'vuedraggable';
import {router, useForm, usePage} from "@inertiajs/vue3";
import {computed, nextTick, onBeforeUnmount, onMounted, ref} from "vue";
import UserAvatar from "@/Components/UserAvatar.vue";
import EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';
import TaskDetailsProjectDialog from "@/Components/Projects/TaskDetailsProjectDialog.vue";
import {Companies} from "@/Helper/exclusion-helper.js";

defineOptions({layout: [Default, DetailsWrapper]})
const props = defineProps({
    project: Object,
    taskLists: Array,
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const taskForm = useForm({
    task_list_id: undefined,
    name: undefined
})

const taskListForm = useForm({
    id: undefined,
    name: undefined,
    color: undefined,
    icon: undefined
})

let requestInProgress = false

const isAddingList = ref(false)
const editListModal = ref(false)
const isTaskDetailDialogOpen = ref(false)

const deleteTaskListDialog = ref(false)
const deleteTaskListLoading = ref(false)
const createTaskLoading = ref(false)

const selectedTaskId = ref(route().params.task)
const selectedTask = computed(() => props.taskLists?.map((taskList) => taskList.tasks)?.flat(1)?.find(task => task.id === selectedTaskId.value))

const eventListener = ref()

onMounted(() => {
    router.on('before', (event) => {
        requestInProgress = true
    })

    router.on('finish', (event) => {
        requestInProgress = false
    })

    eventListener.value = Echo.private(`${user.value.company_id}.project.${props.project.id}`)
        .listen('ProjectUpdated', event => {
            if (!requestInProgress) router.reload()
        })

    if (selectedTask.value) {
        isTaskDetailDialogOpen.value = true
    }
})

onBeforeUnmount(() => {
    eventListener.value?.stopListening('ProjectUpdated')
})

function getTasksFromChecklists(checklists) {
    return checklists?.filter(checklist => checklist.items)?.map(checklist => checklist?.items).flat(1)
}

function createTask() {
    createTaskLoading.value = true

    taskForm.post(
        route('projects.details.tasks.create', props.project.id),
        {
            preserveScroll: true,
            onSuccess: () => taskForm.reset(),
            onFinish: () => createTaskLoading.value = false
        }
    )
}

function createList(bulkCreate) {
    taskListForm.post(
        route('projects.details.board.create-list', props.project.id),
        {
            preserveScroll: true,
            onSuccess: () => {
                taskListForm.reset()
                if (!bulkCreate) isAddingList.value = false
            }
        }
    )
}

function editList() {
    taskListForm.put(
        route('projects.details.board.update-list', {project: props.project.id, taskList: taskListForm.id}),
        {
            preserveScroll: true,
            onSuccess: () => {
                editListModal.value = false
                nextTick(() => taskListForm.reset())
            }
        }
    )
}

function taskDragged(list, {
    added,
    moved
}) {
    const action = added || moved

    if (action) {
        const indexItemBefore = list.tasks.filter(task => task.id !== action.element.id)[action.newIndex - 1]?.index || 0
        const indexItemAfter = list.tasks.filter(task => task.id !== action.element.id)[action.newIndex]?.index || indexItemBefore + 2_000_000_000

        router.patch(route('projects.details.tasks.update-list-index', {
            project: props.project.id,
            task: action.element.id
        }), {
            task_list_id: list.id,
            index: Math.floor((indexItemBefore + indexItemAfter) / 2)
        }, {preserveScroll: true})
    }
}

async function listDragged({moved}) {
    if (moved) {
        const indexItemBefore = props.taskLists.filter(list => list.id !== moved.element.id)[moved.newIndex - 1]?.index || 0
        const indexItemAfter = props.taskLists.filter(list => list.id !== moved.element.id)[moved.newIndex]?.index || indexItemBefore + 2_000_000_000

        router.patch(route('projects.details.board.update-list-index', {
            project: props.project.id,
            taskList: moved.element.id
        }), {
            index: Math.floor((indexItemBefore + indexItemAfter) / 2)
        }, {preserveScroll: true})
    }
}

function fixFooter(e) {
    if (e.relatedContext.list.length === e.draggedContext.futureIndex) {
        const newListDom = e.to;
        const newListFooter = e.to.lastChild;
        nextTick(() => {
            newListDom.appendChild(newListFooter);
        });
    } else {
        let currentListDom = e.from;
        let currentListFooter = e.from.lastChild;
        nextTick(() => {
            currentListDom.appendChild(currentListFooter);
        });
    }
}

function deleteList() {
    deleteTaskListLoading.value = true

    router.delete(route('projects.details.board.delete-list', {project: props.project.id, taskList: taskListForm.id}), {
        preserveScroll: true,
        onSuccess: () => {
            deleteTaskListDialog.value = false
            editListModal.value = false
        },
        onFinish: () => deleteTaskListLoading.value = false
    })
}

function openEditListModal(list) {
    if (user.value.tenant === 'portal')
        return

    Object.assign(taskListForm, list)
    editListModal.value = true
}
</script>

<template>
    <v-container
        :fluid="true"
        class="d-flex flex-column h-100 w-100"
    >
        <div
            class="d-flex flex-grow-1 h-0 pa-0 ga-3 w-100"
            style="overflow: scroll"
        >
            <draggable
                :list="taskLists"
                item-key="id"
                group="lists"
                class="d-flex ga-3"
                :delay="200"
                :delayOnTouchOnly="true"
                :disabled="user.tenant === 'portal'"
                @change="listDragged($event)"
            >
                <template #item="{element: list}">
                    <v-card
                        class="bg-grey-lighten-3 pa-3 align-self-start"
                        style="width: 280px"
                        variant="flat"
                        :rounded="true"
                    >
                        <div class="d-flex flex-column ga-2">
                            <draggable
                                :list="list.tasks"
                                item-key="id"
                                group="cards"
                                class="d-flex flex-column ga-2"
                                :delay="200"
                                :delayOnTouchOnly="true"
                                :move="fixFooter"
                                :disabled="user.tenant === 'portal' && ![Companies.JOINY_DEV, Companies.VALUEVNTRS].includes(user.company_id)"
                                @change="taskDragged(list, $event)"
                            >
                                <template #header>
                                    <div
                                        class="d-flex ga-3 align-center mb-2"
                                        :class="{ 'cursor-pointer': user.tenant !== 'portal' }"
                                        @click="openEditListModal(list)"
                                    >
                                        <v-avatar
                                            :color="list.color"
                                            variant="outlined"
                                        >
                                            <span class="text-h5">{{list.icon}}</span>
                                        </v-avatar>
                                        <h4>{{ list.name }}</h4>
                                    </div>
                                </template>

                                <template #item="{element}">
                                    <v-card
                                        class="pa-2"
                                        variant="flat"
                                        :rounded="true"
                                        @click="selectedTaskId = element.id; isTaskDetailDialogOpen = true"
                                    >
                                        <div class="d-flex flex-column">
                                            <div class="two-lines">
                                                {{ element.name }}
                                            </div>

                                            <div class="d-flex ga-3 align-center">
                                                <div
                                                    v-if="element.deadline"
                                                    class="d-flex align-center ga-1"
                                                >
                                                    <v-icon
                                                        icon="mdi-clock-outline"
                                                        size="x-small"
                                                        color="grey-darken-1"
                                                    />
                                                    <span class="text-caption text-grey-darken-1">
                                                        {{
                                                            new Date(element.deadline).toLocaleString(undefined, {
                                                                day: '2-digit',
                                                                month: '2-digit'
                                                            })
                                                        }}
                                                      </span>
                                                </div>

                                                <div
                                                    v-if="element.description"
                                                    class="d-flex"
                                                >
                                                    <v-icon
                                                        icon="mdi-text"
                                                        size="x-small"
                                                        color="grey-darken-1"
                                                    />
                                                </div>

                                                <div
                                                    v-if="getTasksFromChecklists(element.checklists)?.length"
                                                    class="d-flex ga-1 align-center"
                                                >
                                                    <v-icon
                                                        icon="mdi-checkbox-marked-outline"
                                                        size="x-small"
                                                        color="grey-darken-1"
                                                    />
                                                    <span
                                                        class="text-caption text-grey-darken-1"
                                                    >
                                                        {{
                                                            getTasksFromChecklists(element.checklists)?.filter(task => task?.done)?.length
                                                        }}/{{ getTasksFromChecklists(element.checklists)?.length }}
                                                      </span>
                                                </div>

                                                <div
                                                    v-if="element.comments?.length"
                                                    class="d-flex"
                                                >
                                                    <v-icon
                                                        icon="mdi-comment-outline"
                                                        size="x-small"
                                                        color="grey-darken-1"
                                                    />
                                                </div>

                                                <v-spacer/>

                                                <div
                                                    v-if="element.members?.length"
                                                    class="d-flex align-center"
                                                >
                                                    <div
                                                        v-for="(member, index) in element.members.slice(0, 3)"
                                                        :key="member.id"
                                                        :style="{width: index === element.members.length - 1 ? 'auto' : '15px' }"
                                                    >
                                                        <UserAvatar
                                                            :user="{...member}"
                                                            size="x-small"
                                                            :disable-tooltip="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-card>
                                </template>

                                <template #footer>
                                    <div
                                        v-if="user.tenant !== 'portal'"
                                        class="d-flex flex-column"
                                    >
                                        <v-btn
                                            v-show="!list.isAddingCard"
                                            class="text-none bg-grey-lighten-3"
                                            variant="text"
                                            @click="list.isAddingCard = true; nextTick(() => $refs[`${list.id}-add-card-textarea`]?.focus())"
                                        >
                                            <v-label>
                                                <v-icon icon="mdi-plus"/>
                                                Eine Karte hinzufügen
                                            </v-label>
                                        </v-btn>

                                        <v-card
                                            v-show="list.isAddingCard"
                                            class="pa-2"
                                            variant="flat"
                                            :rounded="true"
                                        >
                                        <textarea
                                            :ref="`${list.id}-add-card-textarea`"
                                            v-model="taskForm.name"
                                            class="w-100 mb-2"
                                            style="resize: none; border: none; outline: none"
                                            placeholder="Geben Sie einen Titel für diese Karte ein..."
                                            rows="2"
                                            @keydown.enter.prevent="taskForm.task_list_id = list.id; createTask()"
                                        />
                                            <v-btn
                                                class="text-none me-2"
                                                color="primary"
                                                :loading="createTaskLoading"
                                                @click="taskForm.task_list_id = list.id; createTask()"
                                            >
                                                Karte hinzufügen
                                            </v-btn>

                                            <v-btn
                                                class="text-none"
                                                icon="mdi-close"
                                                variant="text"
                                                :disabled="createTaskLoading"
                                                @click="list.isAddingCard = false"
                                            />
                                        </v-card>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                    </v-card>
                </template>

                <template #footer>
                    <v-card
                        v-if="user.tenant !== 'portal'"
                        style="width: 280px"
                        :rounded="true"
                        variant="flat"
                        class="d-flex flex-column flex-shrink-0 pa-3 bg-grey-lighten-3 align-self-start"
                    >
                        <v-btn
                            v-show="!isAddingList"
                            class="text-none"
                            variant="text"
                            @click="isAddingList = true; nextTick(() => $refs[`add-list-input`]?.focus())"
                        >
                            <v-label>
                                <v-icon icon="mdi-plus"/>
                                Eine Liste hinzufügen
                            </v-label>
                        </v-btn>

                        <v-card
                            v-if="isAddingList"
                            class="pa-2"
                            :rounded="true"
                            variant="flat"
                        >
                            <input
                                ref="add-list-input"
                                v-model="taskListForm.name"
                                type="text"
                                class="w-100 mb-2 pa-2"
                                placeholder="Listentitel eingeben..."
                                autofocus
                                @keydown.esc="isAddingList = false; taskListForm.reset()"
                                @keydown.enter="createList(true)"
                            >
                            <v-btn
                                class="text-none me-2"
                                color="primary"
                                @click="createList(false)"
                            >
                                Karte hinzufügen
                            </v-btn>

                            <v-btn
                                class="text-none"
                                icon="mdi-close"
                                variant="text"
                                @click="isAddingList = false; taskListForm.reset()"
                            />
                        </v-card>
                    </v-card>
                </template>
            </draggable>
        </div>

        <v-dialog
            v-model="editListModal"
            :persistent="true"
            width="450"
        >
            <template #default>
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Aufgabenstatus bearbeiten</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <div class="d-flex ga-3 align-center mb-2 justify-center">
                                    <v-avatar
                                        :color="taskListForm.color"
                                        variant="outlined"
                                    >
                                        {{ taskListForm.icon }}
                                    </v-avatar>
                                    <h4>{{ taskListForm.name }}</h4>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="taskListForm.color"
                                    variant="outlined"
                                    label="Farbe"
                                    type="color"
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="taskListForm.name"
                                    variant="outlined"
                                    label="Name"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <EmojiPicker
                                    v-model="taskListForm.icon"
                                    class="w-100"
                                    :hide-group-names="true"
                                    :static-texts="{ placeholder: 'Emojis suchen (Englisch)' }"
                                    :disable-skin-tones="true"
                                    :native="true"
                                    @select="taskListForm.icon = $event.i"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            text="Löschen"
                            color="red"
                            variant="tonal"
                            :disabled="taskListForm.processing"
                            @click="deleteTaskListDialog = true"
                        />
                        <v-spacer/>
                        <v-btn
                            variant="text"
                            text="Abbrechen"
                            :disabled="taskListForm.processing"
                            @click="editListModal = false"
                        />
                        <v-btn
                            :loading="taskListForm.processing"
                            color="primary"
                            text="Speichern"
                            @click="editList"
                        />
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <v-dialog
            v-model="deleteTaskListDialog"
            :persistent="true"
            width="auto"
        >
            <v-card>
                <v-card-title class="text-h5">
                    <b>{{ taskListForm.name }}</b> löschen?
                </v-card-title>
                <v-card-text class="d-flex flex-column ga-5">
          <span>
            Möchten Sie den Aufgabenstatus <b>{{ taskListForm.name }}</b> wirklich löschen?
              <br>
              Alle im Aufgabenstatus befindlichen Karten werden dabei gelöscht.
          </span>
                    <v-alert
                        type="warning"
                        text="Dieser Vorgang kann nicht rückgängig gemacht werden!"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        variant="text"
                        :disabled="deleteTaskListLoading"
                        @click="deleteTaskListDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red"
                        prepend-icon="mdi-trash-can-outline"
                        :loading="deleteTaskListLoading"
                        :disabled="deleteTaskListLoading"
                        @click="deleteList"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <TaskDetailsProjectDialog
            v-model="isTaskDetailDialogOpen"
            :task="selectedTask"
            :project="project"
        />
    </v-container>
</template>
