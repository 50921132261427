<script setup>

import {computed, inject, ref, watch} from "vue";
import {Head, router, usePage} from "@inertiajs/vue3";
import {Companies} from "@/Helper/exclusion-helper.js";
import {isAdministrator} from "@/Helper/authorization-helper.js";

const props = defineProps({
    project: Object
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const isMyTasksSelected = ref(route().params.myTasks === 'true')
watch(isMyTasksSelected, newIsMyTasksSelected => {
    router.reload({
        data: {
            'myTasks': newIsMyTasksSelected
        }
    })
})

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {
    title: props.project.name,
    back: route('projects')
})
</script>

<template>
    <Head :title="`${props.project.name} - Projekte`" />

    <v-container
        :fluid="true"
        class="d-flex flex-column ga-3 h-100"
    >
        <div
            v-if="![Companies.VALUEVNTRS, Companies.JOINY_DEV].includes(user.company.id) || isAdministrator(user)"
            class="d-flex align-center"
        >
            <v-tabs
                :model-value="$route().current()"
                centered
                color="grey-darken-2"
            >
                <v-tab
                    class="text-none"
                    text="Details"
                    prepend-icon="mdi-details"
                    value="projects.details"
                    @click="router.visit($route('projects.details', project.id), {
                        data: { myTasks: route().params.myTasks }
                    })"
                />

                <v-tab
                    class="text-none"
                    text="Aufgaben"
                    prepend-icon="mdi-file-check-outline"
                    value="projects.details.tasks"
                    @click="router.visit($route('projects.details.tasks', project.id), {
                        data: { myTasks: route().params.myTasks }
                    })"
                />

                <v-tab
                    class="text-none"
                    text="Board"
                    prepend-icon="mdi-view-column-outline"
                    value="projects.details.board"
                    @click="router.visit($route('projects.details.board', project.id), {
                        data: { myTasks: route().params.myTasks }
                    })"
                />
            </v-tabs>
            <v-spacer />
            <v-tooltip
                text="Meine Aufgaben"
                location="bottom"
            >
                <template #activator="{ props }">
                    <v-avatar
                        class="cursor-pointer"
                        icon="mdi-account-star-outline"
                        :color="isMyTasksSelected ? 'primary' : 'transparent'"
                        v-bind="props"
                        @click="isMyTasksSelected = !isMyTasksSelected"
                    />
                </template>
            </v-tooltip>
        </div>

        <slot />

<!--        <TaskDetailsProjectDialog />-->
    </v-container>
</template>
