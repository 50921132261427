import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp, usePage} from '@inertiajs/vue3';
import {ZiggyVue} from '/vendor/tightenco/ziggy';
import Vuetify from "@/vuetify.js";
import { vuetifyProTipTap } from "@/tiptap.js";
import Notification from '@kyvg/vue3-notification';
import timeago from 'vue-timeago3';
import mitt from "mitt";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

const eventBus = mitt()

Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_JS_API_KEY,
    plugins: [new BugsnagPluginVue()],
    appVersion: import.meta.env.VITE_BUGSNAG_APP_VERSION,
    enabledReleaseStages: ['production']
})
const bugsnagVue = Bugsnag.getPlugin('vue')

createInertiaApp({
    title: (title) => `${title} - ${usePage().props.branding.company_name ?? 'joiny.'}`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        return pages[`./Pages/${name}.vue`]
    },
    setup({el, App, props, plugin}) {
        const vueApp = createApp({render: () => h(App, props)})
            .use(bugsnagVue)
            .use(plugin)
            .use(Vuetify)
            .use(vuetifyProTipTap)
            .use(ZiggyVue)
            .use(Notification)
            .use(timeago)
        vueApp.config.globalProperties.$route = route
        vueApp.config.globalProperties.window = window
        vueApp.config.unwrapInjectedRef = true
        vueApp.provide('eventBus', eventBus)

        return vueApp.mount(el);
    },
    progress: {
        color: '#ffe000',
    },
});
