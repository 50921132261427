<script setup>
import {Head, router, usePage} from '@inertiajs/vue3';
import Default from "@/Layouts/Default.vue";
import {computed, inject, ref} from "vue";
import DeleteProjectDialog from "@/Components/Projects/DeleteProjectDialog.vue";
import NewEditProjectDialog from "@/Components/Projects/NewEditProjectDialog.vue";
import {Companies} from "@/Helper/exclusion-helper.js";
import {isAdministrator} from "@/Helper/authorization-helper.js";

defineOptions({layout: Default})
defineProps({
    projects: Array,
    customers: Array,
    team: Array
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const tableHeaders = ref([])

const isNewProjectDialogOpen = ref(route().params.action === 'new')
const isDeleteProjectDialogOpen = ref(false)
const selectedProject = ref()

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Projekte'})

createTableHeader()

function createTableHeader() {
    tableHeaders.value = [
        {title: 'Projekt', key: 'name'},
        {
            title: 'Start', key: 'start', value: item => {
                return item.start ? `${new Date(item.start).toLocaleDateString(undefined, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                })}` : '-'
            }
        },
        {
            title: 'Ende', key: 'end', value: item => {
                return item.end ? `${new Date(item.end).toLocaleDateString(undefined, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                })}` : '-'
            }
        }
    ]

    if (user.value.tenant === 'console') {
        tableHeaders.value.push({title: 'Budget', key: 'budget'})
        tableHeaders.value.push({title: 'Abrechenbar', key: 'billable'})
    }

    tableHeaders.value.push({title: '', key: 'actions', sortable: false})
}
</script>

<template>
    <Head title="Projekte"/>

    <v-container
        :fluid="true"
        class="overflow-hidden h-100 d-flex flex-column flex-nowrap"
    >
        <v-row v-if="user.permissions.includes('create_projects')" class="flex-grow-0 align-center">
            <v-col class="d-flex">
                <v-btn
                    color="primary"
                    prepend-icon="mdi-plus"
                    text="Projekt"
                    @click="isNewProjectDialogOpen = true"
                />
            </v-col>
        </v-row>

        <v-container
            style="max-width: 1200px"
            class="h-100 d-flex flex-column flex-nowrap"
        >
            <v-row v-if="!projects?.length">
                <v-col class="d-flex flex-column flex-grow-1 align-center justify-center ga-3">
                    <h1>Noch keine Projekte 😅</h1>
                    <img
                        :src="`/img/projects/no_projects.svg`"
                        style="height: 300px"
                        alt=""
                    >
                    <div v-if="user.permissions.includes('create_projects')">
                        <v-btn
                            color="primary"
                            prepend-icon="mdi-plus"
                            text="Erstes Projekt anlegen"
                            @click="isNewProjectDialogOpen = true"
                        />
                    </div>
                </v-col>
            </v-row>
            <v-card
                v-if="projects?.length"
                class="h-100 overflow-y-auto flex-grow-1"
            >
                <v-container
                    :fluid="true"
                    class="d-flex flex-column flex-nowrap"
                >
                    <v-row>
                        <v-col
                            style="overflow-y: scroll"
                        >
                            <v-data-table
                                :items="projects"
                                :items-per-page="0"
                                :headers="tableHeaders"
                                @click:row="(_, {item}) => router.visit(route([Companies.VALUEVNTRS, Companies.JOINY_DEV].includes(user.company.id) && !isAdministrator(user) ? 'projects.details.board' : 'projects.details', item.id))"
                            >
                                <template #item.name="{ item }">
                                    <div class="d-flex ga-3">
                                        <v-avatar
                                            icon="mdi-folder-outline"
                                            variant="tonal"
                                        />

                                        <div class="d-flex flex-column justify-center">
                                            <div>{{ item.name }}</div>
                                            <div
                                                v-if="user.tenant !== 'portal'"
                                                class="text-caption text-grey-darken-1"
                                            >
                                                {{ item.customer.name }}
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #item.budget="{ item }">
                                    {{ item.budget ? item.budget.toLocaleString() : '-' }}
                                    {{
                                        item.budget_type === 'money' ? '€' : item.budget_type === 'time' ? 'Stunden' : ''
                                    }}
                                </template>

                                <template #item.billable="{ item }">
                                    <v-icon
                                        v-if="item.billable"
                                        icon="mdi-check-bold"
                                        color="green"
                                    />
                                    <v-icon
                                        v-if="!item.billable"
                                        icon="mdi-close-thick"
                                        color="red"
                                    />
                                </template>

                                <template #item.actions="{ item }">
                                    <v-menu v-if="user.permissions.includes('delete_projects')">
                                        <template #activator="{ props }">
                                            <v-btn
                                                icon="mdi-dots-vertical"
                                                variant="text"
                                                v-bind="props"
                                            />
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                base-color="red"
                                                prepend-icon="mdi-trash-can-outline"
                                                @click="selectedProject = item; isDeleteProjectDialogOpen = true"
                                            >
                                                <v-list-item-title>Löschen</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>

                                <template #bottom/>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-container>

        <NewEditProjectDialog
            v-model="isNewProjectDialogOpen"
            @close="isNewProjectDialogOpen = false; router.visit(route(route().current()))"
            :customers="customers"
            :team="team"
            :customer-id="route().params.customer"
        />

        <DeleteProjectDialog
            :project="selectedProject"
            :is-open="isDeleteProjectDialogOpen"
            @close="isDeleteProjectDialogOpen = false"
        />
    </v-container>
</template>
