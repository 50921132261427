<script setup>
import Default from "@/Layouts/Default.vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import Wrapper from "@/Pages/Console/Chat/Wrapper.vue";
import {computed, nextTick, onBeforeUnmount, onMounted, ref} from "vue";
import UserAvatar from "@/Components/UserAvatar.vue";
import FileIcon from "@/Components/FileIcon.vue";
import {openInNewTab, validationRules} from "@/helper.js";
import axios from "axios";
import EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';

defineOptions({layout: [Default, Wrapper]})
const props = defineProps({
    messageGroups: Object,
    messageGroup: Object,
    messages: Object,
    chatUsers: Array
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const allMessages = ref(props.messages?.data ?? [])
const allMessageDateGroups = computed(() => {
    let dateGroups = []

    allMessages.value?.forEach(message => {
        const date = new Date(message.created_at).toLocaleDateString(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        if (!dateGroups.find(group => group.name === date)) {
            dateGroups = [{name: date, messages: []}, ...dateGroups]
        }
        dateGroups.find(group => group.name === date).messages.push(message)
    })

    dateGroups.forEach(dateGroup => {
        dateGroup.messages = dateGroup.messages.reverse()
    })

    return dateGroups
})

const form = useForm({
    message: undefined,
    photo: undefined,
    file: undefined
})

const messageGroupForm = useForm({
    id: null,
    name: null,
    users: [],
    type: null
})

const messageTextArea = ref();

const messagesContainer = ref();
const newConversationDialog = ref(false);
const newConversationGroupDialog = ref(false);
const newConversationGroupDialogForm = ref();

const stickyDate = ref();

const photoAttachmentInput = ref();
const fileAttachmentInput = ref();

const eventListener = ref()

onMounted(() => {
    eventListener.value = Echo.private(`chat.${user.value.participant_id}`)
        .listen('ChatMessageReceived', event => {
            axios.get(page.url).then(res => {
                Object.assign(props.messages, res.data.messages)
                Object.assign(props.messageGroups, res.data.messageGroups)

                allMessages.value = props.messages?.data
                page.props.auth.user.unread_chat_messages_count = res.data.unread_chat_messages_count

                if (event.message_group_id === props.messageGroup.id)
                    scrollToLatestMessage()
            })
        })
})

onBeforeUnmount(() => {
    eventListener.value?.stopListening('ChatMessageReceived')
})

async function scrollToLatestMessage() {
    await nextTick()
    messagesContainer.value.$el.lastElementChild?.scrollIntoView()
}

async function sendMessage(type) {
    form.post(route('chat.message-groups.messages.store', {message_group: props.messageGroup.id}), {
        only: ['messages'],
        onSuccess: () => {
            form.reset(type)
            allMessages.value = props.messages?.data
            scrollToLatestMessage()
            nextTick(() => messageTextArea.value.focus())
        }
    })
}

async function downloadAttachment(message) {
    openInNewTab(route('chat.message-groups.messages.download', {
        message_group: props.messageGroup.id,
        message: message.id
    }))
}

async function createNewMessageGroup(type) {
    if (type === 'group' && !(await newConversationGroupDialogForm.value.validate()).valid) return

    messageGroupForm.type = type

    axios.post(route('chat.message-groups.store'), messageGroupForm.data())
        .then((res) => {
            router.visit(res.data)

            messageGroupForm.reset()
            newConversationDialog.value = false
            newConversationGroupDialog.value = false
            // scrollToLatestMessage()
        })
}

async function editMessageGroup() {
    if (!(await newConversationGroupDialogForm.value.validate()).valid) return

    messageGroupForm.put(route('chat.message-groups.update', {message_group: messageGroupForm.id}), {
        onSuccess: () => {
            messageGroupForm.reset()
            newConversationGroupDialog.value = false
            // scrollToLatestMessage()
        }
    })
}

function loadMore({done}) {
    if (!props.messages.links.next) {
        done('empty')
        return
    }

    axios.get(props.messages.links.next).then(res => {
        Object.assign(props.messages, res.data.messages)
        Object.assign(props.messageGroups, res.data.messageGroups)

        allMessages.value = [...allMessages.value, ...props.messages.data]

        router.reload({only: ['messageGroup', 'auth']})
        done('ok')
    })
}

function handleScroll(event) {
    const chatContainer = event.target
    const chatContainerTop = chatContainer.getBoundingClientRect().top
    const dateGroups = chatContainer.querySelectorAll('.message-date-group')

    let lastStickyDate = null

    dateGroups.forEach(group => {
        const header = group.querySelector('.message-date-header');
        const rect = header.getBoundingClientRect();
        if ((rect.top - chatContainerTop) <= 0) {
            lastStickyDate = header.textContent;
        }
    });

    stickyDate.value = lastStickyDate
}
</script>

<template>
    <v-row
        no-gutters
        class="h-100"
    >
        <v-col
            cols="3"
            class="border-e"
        >
            <v-list
                id="message-groups-list"
                class="d-flex flex-column bg-transparent h-100"
            >
                <div class="d-flex align-center px-4 pb-3">
                    <h4>Posteingang</h4>
                    <v-spacer/>
                    <v-btn
                        v-if="user.tenant === 'console'"
                        icon="mdi-pencil-box-outline"
                        @click="newConversationDialog = true"
                    />
                </div>

                <v-list-item
                    v-if="!messageGroups.data?.length"
                    class="flex-grow-1 text-center justify-center"
                >
                    <div class="d-flex flex-column ga-3">
                        <span class="text-h1">📭</span>
                        <span
                            class="text-grey-darken-2"
                        >Der Posteingang ist leer</span>
                    </div>
                </v-list-item>

                <v-list-item
                    v-for="messageGroup in messageGroups.data"
                    :key="messageGroup.id"
                    class="mb-1 rounded-s-lg"
                    :class="{ 'v-list-item--active': window.location.pathname.startsWith(`/chat/message-groups/${messageGroup.id}`) }"
                    min-height="80"
                    :title="messageGroup.name"
                    :subtitle="messageGroup.subtitle"
                    @click.prevent.stop="router.visit(route('chat.message-groups.show', { message_group: messageGroup.id }))"
                >
                    <template #prepend>
                        <div class="me-3">
                            <v-badge color="red" dot :offset-x="3" :offset-y="3"
                                     :model-value="messageGroup.unread_messages_count > 0">
                                <user-avatar
                                    disable-tooltip
                                    :name="messageGroup.name"
                                />
                            </v-badge>
                        </div>
                    </template>
                    <template #append>
                        <div class="ms-1 text-medium-emphasis text-caption">
                            {{ messageGroup.latest_message_date }}
                        </div>
                    </template>

                    <template #title="{ title }">
                        <div class="d-flex ga-1 align-center">
                            <v-icon v-if="messageGroup.type === 'group'" icon="mdi-account-group-outline"/>
                            <div
                                class="overflow-hidden"
                                style="text-overflow: ellipsis"
                            >
                                {{ title }}
                            </div>
                        </div>
                    </template>

                    <template #subtitle="{ subtitle }">
                        <div>{{ subtitle }}</div>
                    </template>
                </v-list-item>
            </v-list>
        </v-col>

        <v-col
            v-if="messageGroup"
            class="d-flex flex-column"
        >
            <v-row class="flex-grow-0">
                <v-col>
                    <div v-if="messageGroup" class="d-flex align-center pa-5 border-b" style="gap: 10px">
                        <UserAvatar
                            :name="messageGroup.name"
                        />

                        <div class="d-flex flex-column">
                            <div class="d-flex ga-1">
                                <v-tooltip
                                    location="bottom"
                                    text="Account deaktiviert"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-icon
                                            v-if="messageGroup.type === 'direct' && messageGroup.participants[0].disabled"
                                            v-bind="props"
                                            icon="mdi-account-cancel-outline"
                                            color="red"
                                        />
                                    </template>
                                </v-tooltip>
                                <h4>{{ messageGroup.name }}</h4>
                            </div>
                            <span>{{ messageGroup.subtitle }}</span>
                        </div>

                        <v-spacer/>

                        <v-btn
                            v-if="messageGroup.type === 'group' && user.tenant === 'console'"
                            icon="mdi-account-box-edit-outline"
                            @click="Object.assign(messageGroupForm, messageGroup); messageGroupForm.users = messageGroup.participants.map(participant => participant.user_id); newConversationGroupDialog = true"
                        />
                    </div>
                </v-col>
            </v-row>

            <v-row
                class="align-start"
            >
                <v-col class="d-flex flex-column h-100">
                    <v-infinite-scroll
                        v-if="Object.keys(allMessages ?? {}).length"
                        class="h-0 flex-grow-1"
                        ref="messagesContainer"
                        side="start"
                        empty-text=""
                        @load="loadMore"
                        @scroll.passive="handleScroll"
                        :margin="50"
                    >
                        <template v-for="messageDateGroup in allMessageDateGroups" :key="messageDateGroup.name">
                            <div class="d-flex flex-column ga-3 message-date-group">
                                <div
                                    class="text-center message-date-header"
                                    :class="{ sticky: stickyDate === messageDateGroup.name }"
                                >
                                    <v-chip
                                        class="text-caption mb-2"
                                        :text="messageDateGroup.name"
                                        color="grey-lighten-3"
                                        variant="flat"
                                    />
                                </div>

                                <div
                                    v-for="message in messageDateGroup.messages"
                                    :key="message.id"
                                    :class="{ 'flex-row-reverse': message.participant?.user_id === user.id }"
                                    class="px-5 d-flex"
                                >
                                    <v-card
                                        :color="message.participant?.user_id === user.id ? 'primary' : ''"
                                        style="height:auto; white-space: normal; max-width: 50%"
                                        class="pa-4 mb-2"
                                        variant="flat"
                                    >
                                        <template #default>
                                            <div class="d-flex flex-column">
                                                <div>
                                                    <span style="font-size: 0.75rem;">{{
                                                            message.participant?.name ?? (message.participant?.user ? `${message.participant?.user?.firstname} ${message.participant?.user?.lastname}` : undefined)
                                                        }}</span>
                                                </div>

                                                <div class="d-flex align-end">
                                                    <div>
                                                        <span
                                                            v-if="message.content_type === 'text'"
                                                            class="flex-grow-1 text-pre-wrap"
                                                            :class="{ 'text-h3': /^\p{Emoji}{1,3}$/u.test(message.content) }"
                                                            v-html="message.content"
                                                        />
                                                        <img
                                                            v-if="message.content_type === 'photo'"
                                                            class="rounded-lg"
                                                            :src="message.content"
                                                            style="height: 320px; max-width: 100%; object-fit: contain"
                                                        />

                                                        <img
                                                            v-if="message.content_type === 'sticker'"
                                                            :src="message.content"
                                                            style="height: 100px; max-width: 100%;"
                                                        />

                                                        <div
                                                            v-if="message.content_type === 'file'"
                                                            class="d-flex ga-1 align-center"
                                                        >
                                                            <FileIcon :file="message.content" size="x-large"/>
                                                            <div class="d-flex flex-column">
                                                                <span>{{ message.content.name }}</span>
                                                                <span class="text-caption">{{
                                                                        message.content.size
                                                                    }}</span>
                                                            </div>
                                                            <v-btn
                                                                icon="mdi-download-circle-outline"
                                                                variant="text"
                                                                @click="downloadAttachment(message)"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="ml-2"
                                                        style="font-size: 0.65rem;"
                                                    >
                                                        {{
                                                            new Date(message.created_at).toLocaleTimeString(undefined, {
                                                                hour: '2-digit',
                                                                minute: '2-digit'
                                                            })
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </v-card>
                                </div>
                            </div>
                        </template>
                    </v-infinite-scroll>
                </v-col>
            </v-row>

            <v-row class="flex-grow-0">
                <v-col class="px-8">
                    <div class="d-flex flex-column border rounded">
                        <v-textarea
                            v-model="form.message"
                            ref="messageTextArea"
                            autofocus
                            rows="3"
                            class="pb-3 px-3 ma-0"
                            :hide-details="true"
                            variant="plain"
                            :auto-grow="true"
                            density="compact"
                            placeholder="Nachricht ..."
                            :disabled="form.processing || (messageGroup.type === 'direct' && messageGroup.participants[0].disabled)"
                            @keydown.enter.exact.prevent="sendMessage('message')"
                        />

                        <div class="d-flex border-t">
                            <input
                                ref="photoAttachmentInput"
                                accept="image/png, image/gif, image/jpeg"
                                type="file"
                                hidden
                                v-on:change="event => {form.photo = event.target.files[0]; sendMessage('photo')}"
                            >
                            <v-btn
                                variant="plain"
                                icon="mdi-image-outline"
                                :disabled="form.processing || (messageGroup.type === 'direct' && messageGroup.participants[0].disabled)"
                                @click="photoAttachmentInput.click()"
                            />

                            <input
                                ref="fileAttachmentInput"
                                type="file"
                                hidden
                                v-on:change="event => {form.file = event.target.files[0]; sendMessage('file')}"
                            >
                            <v-btn
                                variant="plain"
                                icon="mdi-file-outline"
                                :disabled="form.processing || (messageGroup.type === 'direct' && messageGroup.participants[0].disabled)"
                                @click="fileAttachmentInput.click()"
                            />

                            <v-menu
                                :close-on-content-click="true"
                                location="end"
                                width="300"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        variant="plain"
                                        icon="mdi-emoticon-excited-outline"
                                        :disabled="form.processing || (messageGroup.type === 'direct' && messageGroup.participants[0].disabled)"
                                        v-bind="props"
                                    />
                                </template>

                                <EmojiPicker
                                    class="w-100"
                                    :hide-group-names="true"
                                    :static-texts="{ placeholder: 'Emojis suchen (Englisch)' }"
                                    :disable-skin-tones="true"
                                    :native="true"
                                    @select="form.message = `${form.message ?? ''}${$event.i}`; messageTextArea.focus()"
                                />
                            </v-menu>

                            <v-spacer></v-spacer>

                            <v-btn
                                variant="plain"
                                icon="mdi-send"
                                :disabled="!form.message"
                                :loading="form.processing"
                                @click="sendMessage"
                            />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

    <!-- New Conversation Dialog -->
    <v-dialog
        v-model="newConversationDialog"
        width="450"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">Neue Nachricht</span>
            </v-card-title>
            <v-card-text>
                <div
                    v-if="messageGroupForm.processing"
                    class="d-flex flex-column align-center ga-3"
                >
                    <span>Neue Nachrichten-Gruppe wird erstellt</span>
                    <v-progress-circular indeterminate/>
                </div>

                <v-table
                    v-else
                    hover
                >
                    <tbody>
                    <tr
                        class="cursor-pointer"
                        @click="newConversationDialog = false; newConversationGroupDialog = true"
                    >
                        <td>
                            <div class="d-flex flex-row ga-2">
                                <v-icon icon="mdi-plus-circle-multiple-outline"/>
                                <span>Neue Gruppe</span>
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="chatUser in chatUsers"
                        :key="chatUser.id"
                        class="cursor-pointer"
                        @click="messageGroupForm.users = [chatUser.id]; createNewMessageGroup('direct')"
                    >
                        <td>
                            <div class="d-flex flex-column">
                                <span><b>{{ chatUser.firstname }}&nbsp;{{
                                        chatUser.lastname
                                    }}</b>&nbsp;@&nbsp;{{ chatUser.customer?.name ?? chatUser.company.name }}</span>
                                <span class="text-caption text-grey-darken-1">{{ chatUser.email }}</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </v-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    @click="newConversationDialog = false; messageGroupForm.reset()"
                >
                    Abbrechen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- New Conversation Group Dialog -->
    <v-dialog
        v-model="newConversationGroupDialog"
        width="450"
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ messageGroupForm.id ? 'Gruppe ändern' : 'Neue Gruppe' }}</span>
            </v-card-title>
            <v-card-text>
                <div
                    v-if="messageGroupForm.processing"
                    class="d-flex flex-column align-center ga-3"
                >
                    <span v-if="messageGroupForm.id">Gruppe wird geändert</span>
                    <span v-else>Neue Gruppe wird erstellt</span>
                    <v-progress-circular indeterminate/>
                </div>

                <v-form
                    v-else
                    ref="newConversationGroupDialogForm"
                    class="d-flex flex-column ga-3"
                >
                    <v-text-field
                        v-model="messageGroupForm.name"
                        :disabled="form.processing"
                        :rules="[validationRules.required]"
                        label="Name"
                        variant="outlined"
                        :error-messages="messageGroupForm.errors.name"
                        hide-details="auto"
                    />

                    <v-autocomplete
                        v-model="messageGroupForm.users"
                        :items="chatUsers.map(member => { return { title: `${member.firstname} ${member.lastname}`, value: member.id } })"
                        label="Mitglieder"
                        multiple
                        variant="outlined"
                        chips
                        hide-details="auto"
                    />
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    :disabled="messageGroupForm.processing"
                    @click="newConversationGroupDialog = false; messageGroupForm.reset()"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="messageGroupForm.processing"
                    @click="messageGroupForm.id ? editMessageGroup() : createNewMessageGroup('group')"
                >
                    {{ messageGroupForm.id ? 'Ändern' : 'Erstellen' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
#message-groups-list:deep(.v-list-item--active .v-list-item__overlay) {
    background: rgb(var(--v-theme-primary)) !important;
    opacity: 0.15 !important;
}

.message-date-header.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
}
</style>
