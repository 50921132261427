<script setup>
import {Head, router} from "@inertiajs/vue3";
import {inject} from "vue";

const eventBus = inject('eventBus')
eventBus.emit('navBarChanged', {title: 'Einstellungen'})
</script>

<template>
    <Head :title="`Einstellungen`"/>

    <v-container
        :fluid="true"
        class="d-flex flex-column ga-3 h-100"
    >
        <div class="d-flex align-center">
            <v-tabs
                :model-value="route().current()"
                centered
                color="grey-darken-2"
            >
                <v-tab
                    class="text-none"
                    text="Allgemein"
                    prepend-icon="mdi-information-outline"
                    value="settings.index"
                    @click="router.visit(route('settings.index'))"
                />

                <v-tab
                    class="text-none"
                    text="Zugänge"
                    prepend-icon="mdi-cloud-key-outline"
                    value="settings.users.index"
                    @click="router.visit(route('settings.users.index'))"
                />

                <v-tab
                    class="text-none"
                    text="Rollen"
                    prepend-icon="mdi-badge-account-outline"
                    value="settings.roles.index"
                    @click="router.visit(route('settings.roles.index'))"
                />

                <v-tab
                    class="text-none"
                    text="Branding"
                    prepend-icon="mdi-brush-outline"
                    value="settings.branding.index"
                    @click="router.visit(route('settings.branding.index'))"
                />
            </v-tabs>
        </div>

        <slot/>
    </v-container>
</template>
