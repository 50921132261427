import {
    BaseKit,
    Blockquote,
    Bold,
    BulletList,
    Clear,
    Code,
    CodeBlock,
    Color,
    createVuetifyProTipTap,
    FontSize,
    Heading,
    Highlight,
    History,
    Italic,
    Link,
    OrderedList,
    Strike,
    SubAndSuperScript,
    Table,
    Underline,
    VuetifyTiptap,
    VuetifyViewer
} from 'vuetify-pro-tiptap'
import 'vuetify-pro-tiptap/style.css'

export const vuetifyProTipTap = createVuetifyProTipTap({
    lang: 'en',
    components: {
        VuetifyTiptap,
        VuetifyViewer
    },
    extensions: [
        BaseKit.configure({
            characterCount: false
        }),
        History.configure({divider: true}),
        Bold,
        Italic,
        Underline,
        Strike,
        Code.configure({divider: true}),
        Heading,
        FontSize,
        Color,
        Highlight.configure({divider: true}),
        SubAndSuperScript.configure({divider: true}),
        Clear.configure({divider: true}),
        BulletList,
        OrderedList,
        Link,
        Table.configure({divider: true}),
        Blockquote,
        CodeBlock.configure(),
    ]
})
