<script setup>
import Default from "@/Layouts/Default.vue";
import Wrapper from "@/Pages/Console/Settings/Wrapper.vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import UserAvatar from "@/Components/UserAvatar.vue";
import {computed, ref} from "vue";
import {validationRules} from "@/helper.js";

defineOptions({layout: [Default, Wrapper]})
const props = defineProps({
    company: Object,
    teamMemberAddOn: Object,
    roles: Object
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const addEmployeeDialog = ref(false)

const enableDisableEmployeeDialog = ref(false)
const isEmployeeFormValid = ref(false)
const employeeForm = ref()

const rolesList = computed(() => props.roles.data.map(role => { return { title: role.name, value: role.id } }))

const form = useForm({
    id: undefined,
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    role_id: undefined
})

const enableDisableEmployeeForm = useForm({
    employee: undefined,
    disabled: undefined,
})

async function addEditEmployee() {
    form.clearErrors()

    await employeeForm.value.validate()
    if (!isEmployeeFormValid.value) return

    if (form.id) {
        form.put(route('settings.users.update', { user: form.id }), {
            onSuccess: () => {
                addEmployeeDialog.value = false
                form.reset()
            }
        })
    } else {
        form.post(route('settings.users.store'), {
            onSuccess: () => {
                addEmployeeDialog.value = false
                form.reset()
            }
        })
    }
}

function updateEmployeeDisabledStatus() {
    enableDisableEmployeeForm.patch(route('settings.users.disable', {user: enableDisableEmployeeForm.employee.id }), {
        onSuccess: () => {
            enableDisableEmployeeDialog.value = false
            enableDisableEmployeeForm.reset()
        }
    })
}
</script>

<template>
    <v-row>
        <v-col>
            <v-card class="pa-5">
                <div class="d-flex">
                    <div>
                        <h3>Zugänge für Mitarbeitende</h3>
                        <span class="text-caption font-weight-light">{{
                                company.active_users.length
                            }} von {{ company.users.length }} aktiv</span>
                    </div>

                    <v-spacer />

                    <v-btn
                        prepend-icon="mdi-plus"
                        color="primary"
                        text="Mitarbeitenden"
                        @click="addEmployeeDialog = true"
                    />
                </div>

                <v-table
                    class="bg-transparent"
                    :hover="true"
                >
                    <thead>
                    <tr>
                        <th class="font-weight-bold">Name</th>
                        <th class="font-weight-bold text-center">E-Mail</th>
                        <th class="font-weight-bold text-center">Rolle</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="employee in company.users"
                        :key="employee.id"
                        :style="{ 'opacity': employee.disabled ? 0.5 : 1.0 }"
                    >
                        <td>
                            <div class="d-flex ga-3 align-center">
                                <UserAvatar
                                    :user="employee"
                                    size="small"
                                    :disable-tooltip="true"
                                />
                                <div>
                                    {{ employee.firstname }} {{ employee.lastname }}
                                </div>
                                <v-icon
                                    v-if="employee.id === user.id"
                                    icon="mdi-account-star-outline"
                                    color="yellow-darken-3"
                                />
                            </div>
                        </td>
                        <td class="text-center">{{ employee.email }}</td>
                        <td
                            class="text-center"
                        >
                            {{ employee.roles[0]?.name }}
                        </td>
                        <td class="d-flex align-center justify-end">
                            <div
                                v-if="employee.id !== user.id"
                                class="d-flex ga-3"
                            >
                                <v-icon
                                    icon="mdi-pencil-outline"
                                    @click="Object.assign(form, {id: employee.id, ...employee, role_id: employee.roles[0].id}); addEmployeeDialog = true"
                                />

                                <v-icon
                                    v-if="!employee.disabled"
                                    icon="mdi-account-cancel-outline"
                                    color="red"
                                    @click="enableDisableEmployeeForm.employee = {id: employee.id, ...employee}; enableDisableEmployeeForm.disabled = true; enableDisableEmployeeDialog = true"
                                />

                                <v-icon
                                    v-if="employee.disabled"
                                    icon="mdi-account-check-outline"
                                    color="green"
                                    @click="enableDisableEmployeeForm.employee = {id: employee.id, ...employee}; enableDisableEmployeeForm.disabled = false; enableDisableEmployeeDialog = true"
                                />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </v-table>
            </v-card>
        </v-col>

        <v-col>

        </v-col>
    </v-row>

    <!-- Add Employee Dialog -->
    <v-dialog
        v-model="addEmployeeDialog"
        width="450"
        persistent
    >
        <v-form
            v-model="isEmployeeFormValid"
            ref="employeeForm"
            :disabled="form.processing"
            validate-on="none"
            @submit.prevent="addEditEmployee"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Mitarbeitenden {{ form.id ? 'ändern' : 'hinzufügen' }}</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="form.firstname"
                        variant="outlined"
                        label="Vorname"
                        :rules="[validationRules.required]"
                    />

                    <v-text-field
                        v-model="form.lastname"
                        variant="outlined"
                        label="Nachname"
                        :rules="[validationRules.required]"
                    />

                    <v-text-field
                        v-model="form.email"
                        :error-messages="form.errors.email"
                        variant="outlined"
                        label="E-Mail-Adresse"
                        :disabled="!!form.id || form.processing"
                        :rules="[validationRules.required, validationRules.email]"
                    />

                    <v-select
                        v-model="form.role_id"
                        :items="rolesList"
                        variant="outlined"
                        label="Rolle"
                        :rules="[validationRules.required]"
                    />

                    <v-alert
                        v-if="!form.id"
                        type="info"
                    >
                        Durch das Hinzufügen eines Mitarbeitenden werden Ihnen zusätzlich {{ (teamMemberAddOn.unit_amount / 100).toLocaleString() }}&nbsp;€&nbsp;/&nbsp;{{ teamMemberAddOn.recurring_interval === 'month' ? 'Monat' : 'Jahr' }} zzgl. MwSt. in Rechnung gestellt.
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="form.processing"
                        @click="addEmployeeDialog = false; form.reset()"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="primary"
                        :loading="form.processing"
                        type="submit"
                    >
                        {{ form.id ? 'Ändern' : 'Hinzufügen' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>

    <!-- Disable Employee Dialog -->
    <v-dialog
        v-model="enableDisableEmployeeDialog"
        width="auto"
        max-width="600"
    >
        <v-card>
            <v-card-title class="text-h5">
                {{ enableDisableEmployeeForm.employee?.firstname }} {{
                    enableDisableEmployeeForm.employee?.lastname
                }} {{ enableDisableEmployeeForm.disabled ? 'deaktivieren' : 'aktivieren' }}?
            </v-card-title>
            <v-card-text class="d-flex flex-column ga-3">
                    <span>Möchten Sie "{{
                            enableDisableEmployeeForm.employee?.firstname
                        }} {{
                            enableDisableEmployeeForm.employee?.lastname
                        }}" wirklich {{ enableDisableEmployeeForm.disabled ? 'deaktivieren' : 'aktivieren' }}?</span>
                <span v-if="enableDisableEmployeeForm.disabled">
                        "{{ enableDisableEmployeeForm.employee?.firstname }} {{
                        enableDisableEmployeeForm.employee?.lastname
                    }}" kann sich danach nicht mehr einloggen, bis der Account wieder aktiviert wurde.
                    </span>

                <v-alert
                    v-if="!enableDisableEmployeeForm.disabled"
                    type="info"
                >
                    Durch die Aktivierung des Mitarbeitenden werden Ihnen zusätzlich {{ (teamMemberAddOn.unit_amount / 100).toLocaleString() }}&nbsp;€&nbsp;/&nbsp;{{ teamMemberAddOn.recurring_interval === 'month' ? 'Monat' : 'Jahr' }} zzgl. MwSt. in Rechnung gestellt.
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    :disabled="enableDisableEmployeeForm.processing"
                    @click="enableDisableEmployeeForm.reset(); enableDisableEmployeeDialog = false"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    :color="enableDisableEmployeeForm.disabled ? 'red-darken-1' : 'green-darken-1'"
                    :loading="enableDisableEmployeeForm.processing"
                    :disabled="enableDisableEmployeeForm.processing"
                    @click="updateEmployeeDisabledStatus"
                >
                    {{ enableDisableEmployeeForm.disabled ? 'Deaktivieren' : 'Aktivieren' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>

</style>
