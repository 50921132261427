<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useForm} from "@inertiajs/vue3";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faCcAmex, faCcMastercard, faCcPaypal, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import {faBuildingColumns} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {loadStripe} from "@stripe/stripe-js";
import {notify} from "@kyvg/vue3-notification";

library.add(faCcPaypal, faCcVisa, faCcAmex, faCcMastercard, faBuildingColumns)

const props = defineProps({
    isOpen: Boolean,
    products: Array,
    company: Object,
    stripePublishableKey: String
})

const emit = defineEmits([
    'close',
])

const yearlyMonthlySwitch = ref('monthly')
const newSubscriptionForm = useForm({
    name: props.products[0].name,
    price_id: props.products[0].prices[yearlyMonthlySwitch.value].id,
    amount: props.products[0].prices[yearlyMonthlySwitch.value].unit_amount
})
watch(yearlyMonthlySwitch, () => newSubscriptionForm.reset())
const paymentMethodType = ref(props.company.pm_type ? 'default' : 'new')

const stripe = ref()
const elements = ref()
const paymentElement = ref()
const paymentElementCompleted = ref(false)
const selectedPaymentMethod = ref()
const createSubscriptionLoading = ref(false)

const selectedProduct = ref(props.products[0])
watch(selectedProduct, updatedProduct => {
    Object.assign(newSubscriptionForm, {
        name: updatedProduct.name,
        price_id: updatedProduct.prices[yearlyMonthlySwitch.value].id,
        amount: updatedProduct.prices[yearlyMonthlySwitch.value].unit_amount
    })
    loadPaymentElements()
})

onMounted(async () => {
    stripe.value = await loadStripe(props.stripePublishableKey)
    if (paymentMethodType.value === 'new') await loadPaymentElements()
})

function getTotalAmountWithUsers(product) {
    return product.prices[yearlyMonthlySwitch.value].unit_amount / 100 * props.company.active_users.length
}

async function loadPaymentElements() {
    const options = {
        mode: 'subscription',
        amount: newSubscriptionForm.amount,
        currency: 'eur',
        setupFutureUsage: 'off_session',
        paymentMethodTypes: ['card', 'sepa_debit', 'paypal'],
        appearance: {
            theme: 'flat',
            labels: 'floating',
            variables: {
                colorPrimary: '#ffe000',
                colorDanger: '#df1b41',
                fontFamily: 'Roboto, sans-serif',
                borderRadius: '4px',
            }
        }
    }
    elements.value = stripe.value.elements(options)
    paymentElement.value = elements.value.create('payment')
    paymentElement.value.mount('#payment-element')

    paymentElement.value.on('change', event => {
        paymentElementCompleted.value = event.complete
        selectedPaymentMethod.value = event.value.type
    })
}

async function createSubscription() {
    createSubscriptionLoading.value = true

    if (paymentMethodType.value === 'new') {
        const {error: submitError} = await elements.value.submit();
        if (submitError) {
            console.log(submitError)
            createSubscriptionLoading.value = false
            return
        }
    }

    axios.post(
        route('onboarding.create-subscription'),
        {'stripe_price_id': newSubscriptionForm.price_id}
    ).then(async res => {
        if (res.data.client_secret) {
            const {error} = await stripe.value.confirmPayment({
                elements: paymentMethodType.value === 'new' ? elements.value : undefined,
                clientSecret: res.data.client_secret,
                confirmParams: {
                    return_url: window.location.href,
                }
            })

            if (error) {
                console.log(error)
                notify({
                    title: "Es ist ein Fehler aufgetreten!",
                    text: error.message,
                    type: 'error'
                })
            }
        }

        if (res.data.pending_setup_intent && res.data.setup_client_secret) {
            await stripe.value.confirmSetup({
                elements: paymentMethodType.value === 'new' ? elements.value : undefined,
                clientSecret: res.data.setup_client_secret,
                confirmParams: {
                    return_url: window.location.href,
                }
            })
        }

        createSubscriptionLoading.value = false
    })
}
</script>

<template>
    <v-dialog
        :model-value="isOpen"
        width="auto"
    >
        <v-card>
            <v-card-title class="text-h5 text-center">
                Welches Abo passt zu dir?
            </v-card-title>
            <v-card-text>
                <div class="d-flex flex-column ga-3">
                    <div class="d-flex justify-center">
                        <div
                            class="d-flex align-center ga-2"
                            style="width: 180px"
                        >
                            <v-label
                                :clickable="true"
                                @click="yearlyMonthlySwitch = 'monthly'"
                            >
                                monatlich
                            </v-label>
                            <v-switch
                                false-value="monthly"
                                true-value="yearly"
                                v-model="yearlyMonthlySwitch"
                                label="jährlich"
                                :hide-details="true"
                                :disabled="createSubscriptionLoading"
                            />
                        </div>
                    </div>

                    <div class="d-flex ga-2">
                        <div
                            v-for="product in products"
                            class="d-flex flex-column ga-2 border-primary product cursor-pointer"
                            :class="{ 'selected': newSubscriptionForm.price_id === product.prices[yearlyMonthlySwitch].id, 'disabled': createSubscriptionLoading }"
                            style="width: 400px"
                            @click="selectedProduct = product"
                        >
                            <h4>{{ product.name }}</h4>

                            <div>
                                <span class="text-h3">{{
                                        (product.prices[yearlyMonthlySwitch].unit_amount / 100).toLocaleString()
                                    }} €</span><br><span>pro Team-Mitglied</span>
                                <br><span>{{ company.active_users.length }} Team-Mitglied{{ company.active_users.length > 1 ? 'er' : '' }} ≈ {{ (getTotalAmountWithUsers(product)).toLocaleString() }}&nbsp;€</span>
                                <br><span>pro {{ yearlyMonthlySwitch === 'monthly' ? 'Monat' : 'Jahr' }} zzgl. MwSt.</span>
                            </div>
    
                            <div>
                                <span>{{ product.description }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="text-center">
                        <a
                            href="https://joiny.io/preise#pricing"
                            target="_blank"
                        >
                            Weitere Abo-Details
                        </a>
                    </div>

                    <div>
                        <h3>Zahlungsmethode</h3>
                    </div>

                    <div>
                        <v-radio-group
                            v-if="company.pm_type"
                            v-model="paymentMethodType"
                            :disabled="createSubscriptionLoading"
                            @change="loadPaymentElements"
                        >
                            <v-radio value="default">
                                <template #label>
                                    <div class="d-flex ga-3 align-center">
                                        <font-awesome-icon
                                            v-if="company.pm_type === 'sepa_debit'"
                                            :icon="['fas', 'building-columns']"
                                            size="xl"
                                        />

                                        <font-awesome-icon
                                            v-else
                                            :icon="['fab', 'cc-' + company.pm_type]"
                                            size="2xl"
                                        />
                                        <div>***{{ company.pm_last_four }}</div>
                                    </div>
                                </template>
                            </v-radio>
                            <v-radio label="Zahlungsmethode hinzufügen" value="new"></v-radio>
                        </v-radio-group>

                        <div
                            v-show="paymentMethodType === 'new'"
                            class="my-3"
                            id="payment-element"
                        />
                    </div>

                    <div>
                        Mit dem Klick auf "{{ newSubscriptionForm.name }} abonnieren" akzeptiere ich die aktuell
                        geltenden
                        Nutzungsbedingungen und AGB.
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                    :disabled="createSubscriptionLoading"
                    @click="$emit('close')"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    color="primary"
                    :loading="createSubscriptionLoading"
                    @click="createSubscription"
                >
                    {{ newSubscriptionForm.name }} für {{ getTotalAmountWithUsers(selectedProduct) }}&nbsp;€ abonnieren
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>

.product {
    border: 5px solid #ccc;
    border-radius: 20px;
    padding: 20px;
}

.product.selected {
    border: 5px solid rgba(var(--v-theme-primary));
}

.product:hover:not(.selected) {
    border: 5px solid rgba(var(--v-theme-primary), 0.5);
}

.product.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
    cursor: not-allowed;
}

</style>
