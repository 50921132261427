<script setup>
import {loadStripe} from '@stripe/stripe-js'
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {Head, router, useForm, usePage} from "@inertiajs/vue3";
import GuestLayout from "@/Layouts/GuestLayout.vue";
import {validationRules} from "@/helper.js";
import {notify} from "@kyvg/vue3-notification";
import {Confetti} from 'vue-confetti';

const props = defineProps({
    company: Object,
    products: Array,
    stripePublishableKey: String
})

const $confetti = new Confetti()

const isStripeRedirect = computed(() =>
    (route().params.payment_intent && route().params.payment_intent_client_secret && route().params.redirect_status) ||
    (route().params.setup_intent && route().params.setup_intent_client_secret && route().params.redirect_status)
)
const paymentIntent = ref()

const page = usePage()
const user = computed(() => page.props.auth.user)
const branding = computed(() => page.props.branding)

const userForm = useForm({
    firstname: user.value.firstname,
    lastname: user.value.lastname
})

const companyForm = useForm({
    name: props.company.name,
    tax_number: props.company.tax_number,
    street: props.company.street,
    street_number: props.company.street_number,
    zip: props.company.zip,
    city: props.company.city,
    country_code: props.company.country_code,
    email: props.company.email,
})

const step = ref(1)

const completeOnboardingLoading = ref(false)
const initializationLoading = ref(false)
const checkDataLoading = ref(false)
const createSubscriptionLoading = ref(false)
const selectedPlan = ref()
watch(step, async newStep => {
    if (newStep === 5) {
        await nextTick()
        await loadPaymentElements()
    }
})

const stripe = ref()
const elements = ref()
const paymentElement = ref()
const paymentElementCompleted = ref(false)
const selectedPaymentMethod = ref()

const userDataForm = ref()
const companyDataForm = ref()

const selectedPlanPriceString = computed(() => `${(selectedPlan.value?.price.unit_amount / 100).toLocaleString()} € / ${yearlyMonthlySwitch.value === 'monthly' ? 'Monat' : 'Jahr'}`)

const yearlyMonthlySwitch = ref('monthly')

onMounted(async () => {
    initializationLoading.value = true

    stripe.value = await loadStripe(props.stripePublishableKey)

    if (isStripeRedirect.value) {
        if (route().params.payment_intent_client_secret) {
            const {paymentIntent: pi} = await stripe.value.retrievePaymentIntent(route().params.payment_intent_client_secret)
            paymentIntent.value = pi
        } else if (route().params.setup_intent_client_secret) {
            const {setupIntent: si} = await stripe.value.retrieveSetupIntent(route().params.setup_intent_client_secret)
            paymentIntent.value = si
        } else {
            return
        }

        switch (paymentIntent.value.status) {
            case 'succeeded':
                $confetti.start()
                setTimeout(() => $confetti.stop(), 5000)
                break

            default:
                console.log(paymentIntent.value)
        }
    }

    initializationLoading.value = false
})

async function loadPaymentElements() {
    const options = {
        mode: 'subscription',
        amount: selectedPlan.value.price?.unit_amount,
        currency: 'eur',
        setupFutureUsage: 'off_session',
        paymentMethodTypes: ['card', 'sepa_debit', 'paypal'],
        appearance: {
            theme: 'flat',
            labels: 'floating',
            variables: {
                colorPrimary: '#ffe000',
                colorTextPlaceholder: '#dadada',
                colorDanger: '#df1b41',
                fontFamily: 'Roboto, sans-serif',
                spacingUnit: '2px',
                borderRadius: '4px',
            },
            rules: {
                '.Tab': {
                    boxShadow: 'none',
                },
                '.Block': {
                    boxShadow: 'none'
                }
            }
        }
    }
    elements.value = stripe.value.elements(options)
    paymentElement.value = elements.value.create('payment')
    paymentElement.value.mount('#payment-element')

    paymentElement.value.on('change', event => {
        paymentElementCompleted.value = event.complete
        selectedPaymentMethod.value = event.value.type
    })
}

async function saveUser() {
    await nextTick()
    if (!(await userDataForm.value.validate()).valid) {
        return
    }

    userForm.put(route('onboarding.update-user'), {
        onSuccess: () => {
            step.value++
        }
    })
}

async function saveCompany() {
    await nextTick()
    if (!(await companyDataForm.value.validate()).valid) {
        return
    }

    companyForm.put(route('onboarding.update-company'), {
        onSuccess: () => {
            step.value++
        }
    })
}

async function createSubscription() {
    createSubscriptionLoading.value = true

    const {error: submitError} = await elements.value.submit();
    if (submitError) {
        console.log(submitError)
        createSubscriptionLoading.value = false
        return
    }

    axios.post(
        route('onboarding.create-subscription'),
        {'stripe_price_id': selectedPlan.value.price?.id}
    ).then(async res => {
        if (res.data.client_secret) {
            const {error} = await stripe.value.confirmPayment({
                elements: elements.value,
                clientSecret: res.data.client_secret,
                confirmParams: {
                    return_url: window.location.href,
                }
            })

            if (error) {
                console.log(error)
                notify({
                    title: "Es ist ein Fehler aufgetreten!",
                    text: error.message,
                    type: 'error'
                })
            }
        }

        if (res.data.pending_setup_intent && res.data.setup_client_secret) {
            await stripe.value.confirmSetup({
                elements: elements.value,
                clientSecret: res.data.setup_client_secret,
                confirmParams: {
                    return_url: window.location.href,
                }
            })
        }

        createSubscriptionLoading.value = false
    }).finally(() => {
        createSubscriptionLoading.value = false
    })
}

async function checkData() {
    checkDataLoading.value = true

    const {error: submitError} = await elements.value.submit();
    if (submitError) {
        checkDataLoading.value = false
        return
    }

    step.value++
    checkDataLoading.value = false
}

function completeOnboarding() {
    completeOnboardingLoading.value = true
    router.patch(route('onboarding.complete'), {
        payment_intent: route().params.payment_intent,
        setup_intent: route().params.setup_intent,
    }, {
        onFinish: () => {
            completeOnboardingLoading.value = false
        }
    })
}
</script>

<template>
    <GuestLayout>
        <Head title="Onboarding"/>

        <div class="d-flex flex-column flex-grow-1">
            <div class="d-flex flex-column flex-grow-1 align-center justify-center">
                <v-card
                    class="pa-12 pb-8 d-flex flex-column flex-shrink-1"
                    min-height="150"
                    max-width="800"
                    width="80%"
                    rounded="xl"
                    border
                >
                    <v-img
                        class="mx-auto mb-3 flex-grow-0"
                        width="100"
                        :src="branding.logo_url ?? '/img/logo.webp'"
                    />

                    <div class="d-flex flex-grow-1 align-center justify-center" v-if="initializationLoading || !user">
                        <v-progress-circular
                            :indeterminate="true"
                        />
                    </div>

                    <div v-if="!initializationLoading">
                        <div v-if="isStripeRedirect">
                            <div
                                v-if="paymentIntent?.status === 'succeeded'"
                                class="text-center"
                            >
                                <div class="d-flex ga-3 flex-column flex-grow-1 align-center justify-center">
                                    <h2>Dein Konto ist einsatzbereit!</h2>
                                    <v-btn
                                        class="text-none"
                                        color="primary"
                                        size="x-large"
                                        text="Los geht's! 🚀"
                                        :loading="completeOnboardingLoading"
                                        @click="completeOnboarding"
                                    />
                                </div>
                            </div>

                            <div
                                v-if="paymentIntent?.status === 'requires_payment_method'"
                                class="text-center"
                            >
                                <div class="d-flex ga-3 flex-column flex-grow-1 align-center justify-center">
                                    <h2>Bei der Zahlung ist ein Fehler aufgetreten! Bitte versuche es erneut.</h2>
                                    <v-btn
                                        class="text-none"
                                        color="primary"
                                        size="x-large"
                                        text="Nochmal versuchen 😅"
                                        @click="step = 4"
                                    />
                                </div>
                            </div>
                        </div>

                        <div v-if="!isStripeRedirect">
                            <div
                                class="text-center"
                                v-if="step === 1 && user"
                            >
                                <h1 class="mb-3">Willkommen, {{ userForm.firstname }}! 👋</h1>
                                <div class="mb-5">
                  <span>
                    Wir freuen uns, dass du joiny gefunden hast! 🎉<br>Lass uns gemeinsam deine Arbeitsabläufe vereinfachen und
                    optimieren.<br>Falls du Hilfe benötigst, sind wir hier, um zu unterstützen!
                  </span>
                                </div>
                                <v-btn
                                    class="text-none"
                                    color="primary"
                                    size="x-large"
                                    text="Einrichtung starten! 🚀"
                                    @click="step = 2"
                                />
                            </div>

                            <div
                                class="text-center"
                                v-if="step === 2 && user"
                            >
                                <v-form
                                    ref="userDataForm"
                                    validate-on="none"
                                >
                                    <h1 class="mb-3">Stimmt das so?</h1>
                                    <div
                                        class="d-flex ga-3"
                                    >
                                        <v-text-field
                                            class="w-50"
                                            v-model="userForm.firstname"
                                            :disabled="userForm.processing"
                                            label="Vorname"
                                            variant="outlined"
                                            :rules="[validationRules.required]"
                                        />
                                        <v-text-field
                                            class="w-50"
                                            v-model="userForm.lastname"
                                            :disabled="userForm.processing"
                                            label="Nachname"
                                            variant="outlined"
                                            :rules="[validationRules.required]"
                                        />
                                    </div>

                                    <v-btn
                                        class="text-none"
                                        color="primary"
                                        size="large"
                                        text="Weiter"
                                        type="submit"
                                        append-icon="mdi-arrow-right"
                                        :disabled="userForm.processing"
                                        :loading="userForm.processing"
                                        @click="saveUser"
                                    />
                                </v-form>
                            </div>

                            <div
                                class="text-center"
                                v-if="step === 3"
                            >
                                <v-form
                                    ref="companyDataForm"
                                    validate-on="none"
                                >
                                    <h1 class="mb-3">Wie lauten deine Firmen-Daten?</h1>
                                    <div
                                        class="d-flex ga-3"
                                    >
                                        <v-text-field
                                            class="w-50"
                                            v-model="companyForm.name"
                                            :disabled="companyForm.processing"
                                            label="Firmenname"
                                            variant="outlined"
                                            :rules="[validationRules.required]"
                                        />
                                        <v-text-field
                                            class="w-50"
                                            v-model="companyForm.tax_number"
                                            :disabled="companyForm.processing"
                                            label="USt.-Id"
                                            variant="outlined"
                                            :rules="[validationRules.required, validationRules.taxId]"
                                        />
                                    </div>
                                    <div
                                        class="d-flex ga-3"
                                    >
                                        <v-text-field
                                            v-model="companyForm.street"
                                            :disabled="companyForm.processing"
                                            label="Straße"
                                            variant="outlined"
                                            :rules="[validationRules.required]"
                                        />
                                        <v-text-field
                                            v-model="companyForm.street_number"
                                            :disabled="companyForm.processing"
                                            label="Hausnummer"
                                            variant="outlined"
                                            :rules="[validationRules.required]"
                                        />
                                    </div>
                                    <div
                                        class="d-flex ga-3"
                                    >
                                        <v-text-field
                                            v-model="companyForm.zip"
                                            :disabled="companyForm.processing"
                                            label="Postleitzahl"
                                            variant="outlined"
                                            :rules="[validationRules.required]"
                                        />
                                        <v-text-field
                                            v-model="companyForm.city"
                                            :disabled="companyForm.processing"
                                            label="Ort"
                                            variant="outlined"
                                            :rules="[validationRules.required]"
                                        />
                                    </div>

                                    <div
                                        class="d-flex ga-3"
                                    >
                                        <div class="w-50">
                                            <v-select
                                                v-model="companyForm.country_code"
                                                :items="[{ title: 'Deutschland', value: 'DE' }, { title: 'Österreich', value: 'AT' }, { title: 'Schweiz', value: 'CH' }]"
                                                :disabled="companyForm.processing"
                                                label="Land"
                                                variant="outlined"
                                                :rules="[validationRules.required]"
                                            />
                                        </div>

                                        <div class="w-50">
                                            <v-text-field
                                                v-model="companyForm.email"
                                                :disabled="companyForm.processing"
                                                label="E-Mail-Adresse"
                                                variant="outlined"
                                                :rules="[validationRules.required, validationRules.email]"
                                            />
                                        </div>
                                    </div>

                                    <v-btn
                                        class="text-none"
                                        color="primary"
                                        size="large"
                                        text="Weiter"
                                        type="submit"
                                        append-icon="mdi-arrow-right"
                                        :disabled="companyForm.processing"
                                        :loading="companyForm.processing"
                                        @click="saveCompany"
                                    />
                                </v-form>
                            </div>

                            <div
                                v-if="step === 4"
                            >
                                <h1 class="text-center">Welches Abo passt zu dir?</h1>

                                <div class="d-flex justify-center">
                                    <div
                                        class="d-flex align-center ga-2"
                                        style="width: 180px"
                                    >
                                        <v-label
                                            :clickable="true"
                                            @click="yearlyMonthlySwitch = 'monthly'"
                                        >
                                            monatlich
                                        </v-label>
                                        <v-switch
                                            false-value="monthly"
                                            true-value="yearly"
                                            v-model="yearlyMonthlySwitch"
                                            label="jährlich"
                                            :hide-details="true"
                                        />
                                    </div>
                                </div>

                                <div class="d-flex flex-column ga-3 mb-3">
                                    <v-card
                                        v-for="product in products"
                                        :key="product.id"
                                        class="align-center pa-3 d-flex"
                                        border="md"
                                        @click="selectedPlan = { name: product.name, period: yearlyMonthlySwitch, price: product.prices[yearlyMonthlySwitch] }; step++"
                                    >
                                        <div class="flex-grow-1">
                                            <h2>{{ product.name }}</h2>
                                            <div class="d-flex flex-column">
                                                <span v-for="feature in product.features">✅&nbsp;{{ feature }}</span>
                                                <span>🧑‍💻&nbsp;{{
                                                        (product.team_member_prices[yearlyMonthlySwitch].unit_amount / 100).toLocaleString()
                                                    }} € / Team-Mitglied / {{ yearlyMonthlySwitch === 'monthly' ? 'Monat' : 'Jahr' }}</span>
                                            </div>
                                        </div>
                                        <div class="flex-shrink-0 text-end">
                      <span class="text-h3">{{
                              (product.prices[yearlyMonthlySwitch].unit_amount / 100).toLocaleString()
                          }} €</span><span>/ {{ yearlyMonthlySwitch === 'monthly' ? 'Monat' : 'Jahr' }}</span>
                                        </div>
                                    </v-card>
                                </div>

                                <div class="text-center text-caption">
                                    Alle Preise verstehen sich zzgl. MwSt.
                                </div>

                                <div class="text-center">
                                    <a
                                        href="https://joiny.io/preise#pricing"
                                        target="_blank"
                                    >
                                        Weitere Plan-Details
                                    </a>
                                </div>
                            </div>

                            <div
                                class="text-center"
                                v-show="step === 5"
                            >
                                <h1 class="text-center">Wie möchtest du bezahlen?</h1>

                                <div class="my-3" id="payment-element"/>

                                <v-btn
                                    class="text-none"
                                    color="primary"
                                    size="large"
                                    text="Überprüfen"
                                    append-icon="mdi-arrow-right"
                                    :disabled="!paymentElementCompleted"
                                    :loading="checkDataLoading"
                                    @click="checkData"
                                />
                            </div>

                            <div
                                v-if="step === 6"
                            >
                                <h1 class="text-center">Passt alles so?</h1>

                                <div class="my-5">
                                    <v-table
                                        class="bg-transparent font-weight-bold"
                                    >
                                        <tbody>
                                        <tr>
                                            <td>Ich heiße</td>
                                            <td class="py-3"><b>{{ user?.firstname }} {{ user?.lastname }}</b></td>
                                            <td>
                                                <v-btn icon="mdi-pencil" size="x-small" color="primary"
                                                       @click="step = 2"/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Meine Firma</td>
                                            <td class="py-3"><b>{{ company?.name }}<br>{{ company?.street }}
                                                {{ company?.street_number }}<br>{{ company?.zip }}
                                                {{ company?.city }}<br>USt-ID: {{ company?.tax_number }}</b></td>
                                            <td>
                                                <v-btn icon="mdi-pencil" size="x-small" color="primary"
                                                       @click="step = 3"/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Gewählter Plan</td>
                                            <td class="py-3"><b>{{ selectedPlan?.name }}<br>{{
                                                    selectedPlanPriceString
                                                }}</b></td>
                                            <td>
                                                <v-btn icon="mdi-pencil" size="x-small" color="primary"
                                                       @click="step = 4"/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Gewählte Zahlungsmethode</td>
                                            <td class="py-3"><b>{{
                                                    selectedPaymentMethod === 'card' ? 'Kreditkarte' : selectedPaymentMethod === 'paypal' ? 'PayPal' : selectedPaymentMethod === 'sepa_debit' ? 'SEPA Lastschrift' : '-'
                                                }}</b></td>
                                            <td>
                                                <v-btn icon="mdi-pencil" size="x-small" color="primary"
                                                       @click="step = 5"/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </v-table>
                                </div>

                                <div class="text-center">
                                    <v-btn
                                        class="text-none"
                                        color="primary"
                                        size="x-large"
                                        :text="`Abo für ${selectedPlanPriceString} starten 🚀`"
                                        :loading="createSubscriptionLoading"
                                        @click="createSubscription"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>
        </div>
    </GuestLayout>
</template>
