<script setup>

import {computed} from "vue";

const props = defineProps({
    name: {
        default: undefined,
        type: String
    },
    user: {
        default: undefined,
        type: Object
    },
    size: {
        default: "large",
        type: String
    },
    disableTooltip: {
        default: false,
        type: Boolean
    }
})

const fontSize = computed(() => {
    switch (props.size) {
        case 'x-small':
        case 'small':
            return '12px'

        default:
            return 'inherit'
    }
})

const nameInitials = computed(() => {
    const splittedName = props.name.split(' ')
    const firstLetter = [...splittedName[0]][0]
    const secondLetter = [...(splittedName[1] ?? [])][0]

    return `${firstLetter}${secondLetter ?? ''}`
})
</script>

<template>
    <v-avatar
        :color="user?.color ?? 'primary'"
        :size="size"
    >
        <span
            v-if="user"
            class="font-weight-bold"
            style="user-select: none;"
            :style="{ 'font-size': fontSize }"
        >
          {{ user.firstname?.slice(0, 1) }}{{ user.lastname?.slice(0, 1) }}
        </span>

        <span
            v-else-if="name"
            class="font-weight-bold"
            style="user-select: none;"
            :style="{ 'font-size': fontSize }"
        >
          {{ nameInitials }}
        </span>

        <v-tooltip
            v-if="!disableTooltip && user"
            activator="parent"
            location="bottom"
            :text="`${user?.firstname} ${user?.lastname}`"
        />
    </v-avatar>
</template>
