<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import {Head, Link, useForm, usePage} from '@inertiajs/vue3';
import {validationRules} from "@/helper.js";
import {computed} from "vue";

const props = defineProps({
    email: {
        type: String,
        required: true,
    },
    token: {
        type: String,
        required: true,
    },
    error: {
        type: String,
        required: false,
    },
});

const page = usePage()
const branding = computed(() => page.props.branding)

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('password.store'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Passwort zurücksetzen"/>

        <div class="d-flex flex-column flex-grow-1">
            <div class="d-flex flex-column flex-grow-1 align-center justify-center">
                <v-card
                    class="pa-12 pb-8 flex-shrink-1"
                    width="448"
                    rounded="xl"
                >
                    <v-img
                        class="mx-auto mb-8"
                        width="228"
                        :src="branding.logo_url ?? '/img/logo.webp'"
                    />

                    <v-alert
                        v-if="props.error"
                        class="my-5"
                        type="error"
                        :text="props.error"
                    />

                    <v-form
                        v-if="!props.error"
                        ref="resetForm"
                        @submit.prevent="submit"
                    >
                        <input
                            v-model="form.email"
                            autocomplete="username"
                            hidden
                        />

                        <v-text-field
                            v-model="form.password"
                            :disabled="form.processing"
                            :rules="[validationRules.required]"
                            density="compact"
                            label="Neues Passwort"
                            autocomplete="new-password"
                            prepend-inner-icon="mdi-lock-outline"
                            variant="outlined"
                            :clearable="true"
                            type="password"
                            :error-messages="form.errors.password"
                            tabindex="1"
                        />

                        <v-text-field
                            v-model="form.password_confirmation"
                            :disabled="form.processing"
                            :rules="[validationRules.required]"
                            density="compact"
                            label="Passwort bestätigen"
                            autocomplete="new-password"
                            prepend-inner-icon="mdi-lock-check-outline"
                            variant="outlined"
                            :clearable="true"
                            type="password"
                            :error-messages="form.errors.password_confirmation"
                            tabindex="2"
                        />

                        <v-btn
                            block
                            class="text-none"
                            color="primary"
                            size="x-large"
                            type="submit"
                            :loading="form.processing"
                            tabindex="3"
                        >
                            Passwort setzen
                        </v-btn>

                    </v-form>

                    <v-card-text class="text-center d-flex flex-column ga-3">
                        <Link
                            class="text-decoration-none"
                            rel="noopener noreferrer"
                            href="/login"
                        >Zum Login
                        </Link>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </GuestLayout>
</template>
