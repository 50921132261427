import axios from "axios";

export const validationRules = {
    required: (v) => {
        return !!v || 'Feld ist ein Pflichtfeld'
    },
    email: (v) => {
        return !v || /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || 'Wert ist keine E-Mail-Adresse'
    },
    taxId: (v) => {
        return !v || /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/.test(v) || 'Wert ist keine gültige Umsatzsteuer-IdNr.'
    },
    url: (v) => {
        return !v || /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/.test(v) || 'Wert ist keine gültige URL mit URL-Schema'
    },
    domain: (v) => {
        return !v || /[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/.test(v) || 'Wert ist keine gültige Domain'
    },
    max100Percent: (v) => {
        return !v || parseFloat(v.replace(',', '.')) <= 100.0 || 'Wert über 100% nicht gültig.'
    },
}

export function openInNewTab(url) {
    window.open(url, '_blank')
}

export function reloadGlobalAuthProp(page) {
    axios.get(page.url, {
        headers: {
            'Accept': 'text/html',
            'X-Inertia': true,
            'X-Inertia-Partial-Component': page.component,
            'X-Inertia-Partial-Data': 'auth',
            'X-Inertia-Version': page.version
        }
    }).then(res => {
        page.props.auth = res.data.props.auth
    })
}
